.gallery {
	display: block;
	padding-bottom: 0.5625rem;
	position: relative;
	width: 100%;
}

@media (--mq-up-to-small) {
	.gallery {
		margin-right: -1.25rem;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);
	}
}

@media (--mq-from-wide) {
	.gallery {
		padding-bottom: 0.75rem;
	}
}

@media (--mq-up-to-small) {
	.form-accordion__gallery .gallery,
	.u-frame-all-around-borders .gallery {
		margin-right: 0;
		margin-left: 0;
		width: 100%;
	}
}

.gallery__slides-wrapper {
	display: block;
	overflow: visible;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 2;
}

.gallery__slides-wrapper[data-aspect-ratio],
.gallery__slides-wrapper[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

.gallery__slides-wrapper[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.gallery__slides-wrapper[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.gallery__slides-wrapper[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

.gallery__slides-wrapper[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

.gallery__slides-wrapper[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

.gallery__slides-wrapper[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

.gallery__slides-wrapper[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

.gallery__slides-wrapper[data-aspect-ratio="16:11"] {
	padding-top: calc(100% * (11 / 16));
}

.gallery__slides-wrapper[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

.gallery__slide {
	display: block;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
}

.gallery__toggle:checked + .gallery__slide {
	opacity: 1;
	pointer-events: all;
	transition: opacity 96ms linear;
}

.gallery__slide__next {
	-webkit-tap-highlight-color: var(--color-transparent-white-0);
	display: block;
	overflow: hidden;
	padding-top: 0 !important;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: none;
	height: calc(100% - 2rem);
	z-index: -1;
}

@media (--mq-from-small) {
	.gallery__slide__next {
		height: calc(100% - 3rem);
	}
}

.gallery__slide__next:focus {
	outline: 0;
}

.gallery__toggle:checked + .gallery__slide .gallery__slide__next {
	cursor: pointer;
	pointer-events: all;
	z-index: 2;
}

.gallery__asset {
	display: block;
	overflow: hidden;
	padding-top: 0 !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	max-width: none;
	height: 100%;
	z-index: 1;
}

/*
 * If you change the size of these, also change the calculation
 * of the inline CSS in gallery.twig
 */
.gallery__navigation-label {
	background-color: var(--color-white);
	border-radius: 100%;
	display: block;
	position: absolute;
	bottom: -0.5625rem;
	width: 1.125rem;
	height: 1.125rem;
	z-index: 3;
}

@media (--mq-from-wide) {
	.gallery__navigation-label {
		bottom: -0.75rem;
		width: 1.5rem;
		height: 1.5rem;
	}
}

.gallery__navigation-label:focus {
	outline: 0;
}

.gallery__navigation-label:hover {
	cursor: pointer;
}

.gallery__navigation-label::after {
	background-color: var(--color-black);
	border-radius: 100%;
	content: "";
	display: block;
	opacity: 0;
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
	transform: scale(0);
	transition:
		opacity 64ms 32ms linear,
		transform 128ms ease-out;
	width: 0.625rem;
	height: 0.625rem;
	z-index: 3;
}

@media (--mq-from-wide) {
	.gallery__navigation-label::after {
		top: 0.3125rem;
		left: 0.3125rem;
		width: 0.875rem;
		height: 0.875rem;
	}
}

.gallery__toggle:checked + .gallery__slide + .gallery__navigation-label::after,
.gallery__navigation-label:focus::after,
.gallery__navigation-label:hover::after {
	opacity: 1;
	transform: scale(1);
	transition:
		opacity 16ms linear,
		transform 320ms cubic-bezier(0.73, 0, 0.015, 1);
}
