.grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
}

.grid__item {
	flex-basis: calc(50% - 0.3125rem);
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: 1.25rem;
	margin-right: 0.625rem;
	overflow: hidden;
}

@media (--mq-from-small) {
	.grid__item {
		flex-basis: calc(33.3333% - 0.75rem);
		margin-right: 1.125rem;
	}
}

@media (--mq-from-wide) {
	.grid__item {
		margin-top: 3.125rem;
	}
}

.grid__item:first-child,
.grid__item:nth-child(2) {
	margin-top: 0;
}

@media (--mq-from-small) {
	.grid__item:nth-child(3) {
		margin-top: 0;
	}
}

@media (--mq-up-to-small) {
	.grid__item:nth-child(2n + 2) {
		margin-right: 0;
	}
}

@media (--mq-from-small) {
	.grid__item:nth-child(3n + 3) {
		margin-right: 0;
	}
}
