.media {
	display: block;
	position: relative;
	width: 100%;
}

@media (--mq-up-to-small) {
	.media.media--bleed {
		margin-right: -1.25rem;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);
	}
}

@media (--mq-up-to-small) {
	.u-frame-all-around-borders .media {
		margin-right: 0;
		margin-left: 0;
		width: 100%;
	}
}

.media[data-aspect-ratio] {
	overflow: hidden;
	padding-top: 100%;
}

.media[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.media[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.media[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

.media[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

.media[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

.media[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

.media[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

.media[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

.media[data-aspect-ratio="16:11"] {
	padding-top: calc(100% * (11 / 16));
}

.media[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

.media__asset {
	display: block;
	width: 100%;
	height: auto;
}

.media[data-aspect-ratio]:not([data-aspect-ratio="0"]) .media__asset {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	z-index: 0;
}

@supports (object-fit: cover) {
	.media[data-aspect-ratio]:not([data-aspect-ratio="0"]) .media__asset {
		object-fit: cover;
		top: 0;
		left: 0;
		transform: none;
		max-width: none;
		height: 100%;
	}
}

.media__caption {
	box-sizing: border-box;
	color: var(--color-white);
	font-size: 0.75rem;
	font-weight: 500;
	padding: 1rem 1.25rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

@media (--mq-from-small) {
	.media__caption {
		padding-top: 1.6875rem;
		padding-bottom: 1.6875rem;
	}
}

@media (--mq-from-wide) {
	.media__caption {
		font-size: 0.8125rem;
		padding: 1.75rem 2.0625rem;
	}
}

.media--has-dark-caption .media__caption {
	color: var(--color-grey);
}
