@media (--mq-from-wide) {
	.site-footer {
		line-height: 1.44;
	}
}

.site-footer__legal {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

@media (--mq-from-wide) {
	.site-footer__legal {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}

.site-footer__legal__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (--mq-from-xsmall) {
	.site-footer__legal__wrapper {
		flex-direction: row;
		justify-content: space-between;
	}
}

@media (--mq-up-to-xsmall) {
	.site-footer__legal-navigation-container {
		margin-top: var(--space-xsmall);
	}
}
