.logo {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-size: 0;
	line-height: 0;
	padding: 0.9375rem 1.25rem;
}

@media (--mq-from-small) {
	.logo {
		display: flex;
		overflow: hidden;
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;
		align-items: center;
		position: relative;
	}
}

@media (--mq-from-wide) {
	.logo {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

@media (--mq-from-small) {
	.logo::before {
		background-color: currentColor;
		border-radius: 50%;
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate3d(-50%, 100%, 0);
		transition: transform 320ms ease-in-out;
		width: 1rem;
		height: 1rem;
	}
}

@media (--mq-from-small) {
	.logo:hover::before,
	.logo:focus::before,
	.logo.logo--active::before {
		transform: translate3d(-50%, 0.625rem, 0);
	}
}

.logo .icon-logo {
	width: 102px;
	height: 22px;
}

@media (--mq-from-small) {
	.logo .icon-logo {
		width: 93px;
		height: 20px;
	}
}
