:root {
	--color-black: #000000;
	--color-green: #41b99d;
	--color-grey: #bdbdbd;
	--color-white: #ffffff;
	/*  */
	--color-transparent-black-0: rgb(0 0 0 / 0);
	--color-transparent-white-0: rgb(255 255 255 / 0);
	/*  */
	--font-sans: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	--font-title: titling-gothic-fb, "Trebuchet MS", "Arial", sans-serif;
	--font-title-condensed: titling-gothic-fb-condensed, "Arial Narrow", "Arial", sans-serif;
	/*  */
	--font-title-fallback: "Trebuchet MS", "Arial", sans-serif;
	--font-title-condensed-fallback: "Arial Narrow", "Arial", sans-serif;
	/*  */
	--font-weight-light: 400;
	--font-weight-medium: 600;
	--font-weight-bold: 700;
	/*  */
	--site-header-height: 66px;
	--site-header-height-small: 80px;
	--site-header-height-wide: 100px;
	/*  */
	--space-xxsmall: 10px;
	--space-xsmall: 20px;
	--space-small: 40px;
	--space-medium: 65px;
	--space-large: 80px;
	--space-xlarge: 100px;
}
