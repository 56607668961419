.l-faq .faq-list {
	height: 100%;
}

.faq-list__title-container {
	background-color: var(--color-black);
	color: var(--color-white);
	overflow: hidden;
	position: relative;
	width: 100%;
}

/* If you change this, also change the marquee calculations in faq/list.twig */
.faq-list__title {
	display: block;
	font-family: var(--font-title);
	font-size: 1.25rem;
	line-height: 4rem;
	position: relative;
	text-transform: none;
	white-space: nowrap;
	width: 100%;
	height: 4rem;
}

@media (--mq-from-wide) {
	.faq-list__title {
		font-size: 1.5rem;
		line-height: 3.625rem;
		height: 3.625rem;
	}
}

.sg-kitchensink__fallback-font .faq-list__title,
html:not(.wf-active) .faq-list__title {
	font-family: var(--font-title-fallback);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.95px;
}

@media (--mq-from-small) {
	.sg-kitchensink__fallback-font .faq-list__title,
	html:not(.wf-active) .faq-list__title {
		letter-spacing: 1.25px;
	}
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font .faq-list__title,
	html:not(.wf-active) .faq-list__title {
		letter-spacing: 1.7px;
	}
}

.faq-list__accordion {
	border-right: 2px solid var(--color-black);
	border-left: 2px solid var(--color-black);
	box-sizing: border-box;
	position: relative;
	width: 100%;
}

.faq-list__accordion__item {
	border-top: 2px solid var(--color-black);
	box-sizing: border-box;
	width: 100%;
}

.faq-list__accordion__item:last-child {
	border-bottom: 2px solid var(--color-black);
}

.faq-list__title-container ~ .faq-list__accordion .faq-list__accordion__item:first-child {
	border-top: 0;
}

.faq-list__accordion__checkbox {
	display: none;
}

.faq-list__accordion__question {
	display: block;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	line-height: 1.43;
	margin-top: 0;
	margin-bottom: 0;
	position: relative;
	transition: color 96ms linear;
	width: 100%;
}

@media (--mq-from-medium) {
	.faq-list__accordion__question {
		font-size: 1rem;
	}
}

@media (--mq-from-wide) {
	.faq-list__accordion__question {
		font-size: 1.3125rem;
		line-height: 1.71;
	}
}

.faq-list__accordion__question:focus,
.faq-list__accordion__question:hover,
.faq-list__accordion__checkbox:checked ~ .faq-list__accordion__question {
	color: var(--color-green);
}

.faq-list__accordion__question::after {
	color: currentColor;
	content: "+";
	display: block;
	font-size: inherit;
	line-height: 1;
	pointer-events: none;
	position: absolute;
	top: 0.5rem;
	right: 0.375rem;
	z-index: 1;
}

@media (--mq-from-wide) {
	.faq-list__accordion__question::after {
		font-size: 1.5rem;
		top: 0.625rem;
		right: 1.125rem;
	}
}

.faq-list__accordion__checkbox:checked ~ .faq-list__accordion__question::after {
	content: "×";
}

.sg-kitchensink__fallback-font .faq-list__accordion__question,
html:not(.wf-active) .faq-list__accordion__question {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-light);
	letter-spacing: 0;
}

.faq-list__accordion__question__toggle {
	box-sizing: border-box;
	display: block;
	padding: 0.375rem 2.875rem 0.375rem 0.375rem;
	position: relative;
	width: 100%;
	z-index: 1;
}

@media (--mq-from-wide) {
	.faq-list__accordion__question__toggle {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1.125rem;
	}
}

.faq-list__accordion__question__toggle:focus {
	outline: 0;
}

.faq-list__accordion__question__toggle:hover {
	cursor: pointer;
}

.faq-list__accordion__answer {
	box-sizing: border-box;
	padding-right: 0.375rem;
	padding-bottom: 0.375rem;
	padding-left: 0.375rem;
	width: 100%;
}

@media (--mq-from-wide) {
	.faq-list__accordion__answer {
		padding-right: 1.125rem;
		padding-bottom: 1rem;
		padding-left: 1.125rem;
	}
}

.faq-list__accordion__checkbox:not(:checked) ~ .faq-list__accordion__answer {
	display: none;
}
