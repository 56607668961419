.big-text {
	display: block;
	text-align: center;
	width: 100%;
}

.big-text__svg {
	display: none;
	overflow: hidden;
	width: 100%;
}

/* Selects every browser but IE */

@supports not (-ms-high-contrast: none) {
	.big-text__svg {
		display: block;
	}
}

.wf-inactive .big-text__svg {
	display: none !important;
}

.big-text__svg__text {
	display: inline-block;
	/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
	font-family: titling-gothic-fb-condensed;
	font-size: 800px;
	opacity: 0;
	text-transform: uppercase;
	transition: opacity 96ms ease-in-out;
	white-space: nowrap;
}

.wf-active .big-text__svg__text {
	opacity: 1;
}

@supports not (-ms-high-contrast: none) {
	.big-text__fallback {
		display: none;
	}
}

.wf-inactive .big-text__fallback {
	display: block !important;
}
