.mosaic {
	background-color: var(--color-black);
	position: relative;
	width: 100%;
}

@media (--mq-up-to-small) {
	.mosaic {
		margin-right: -1.25rem;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);
	}
}

@media (--mq-up-to-small) {
	.article-wrapper--media .mosaic,
	.u-frame-all-around-borders .mosaic {
		margin-right: 0;
		margin-left: 0;
		width: 100%;
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row {
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		width: 100%;
	}
}

/* Rows have min 1, max 8 items */
@media (--mq-from-xsmall) {
	.mosaic__row__column {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-2 {
		flex-basis: calc(100% / 2);
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-3 {
		flex-basis: calc(100% / 3);
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-4 {
		flex-basis: calc(100% / 4);
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-5 {
		flex-basis: calc(100% / 5);
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-6 {
		flex-basis: calc(100% / 6);
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-7 {
		flex-basis: calc(100% / 7);
	}
}

@media (--mq-from-xsmall) {
	.mosaic__row__column.mosaic__row__column--size-8 {
		flex-basis: calc(100% / 8);
	}
}
