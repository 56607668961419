.shape.shape--circle {
	clip-path: circle(50% at 50% 50%);
}

.shape.shape--triangle-down {
	clip-path: polygon(0 0, 50% 100%, 100% 0);
}

.shape.shape--triangle-up {
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.shape.shape--has-no-attachment {
	padding-top: 100%;
}
