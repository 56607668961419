.site-header {
	background-color: var(--color-white);
	border-bottom: 2px solid var(--color-black);
	color: var(--color-black);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
}

.l-styleguide .site-header {
	position: relative;
	top: auto;
	left: auto;
}

.site-header__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

@media (--mq-up-to-small) {
	.site-header__wrapper {
		padding-left: 0 !important;
	}
}

.site-header__actions-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
