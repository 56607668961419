.banner {
	background-color: var(--color-grey);
	box-sizing: border-box;
	color: var(--color-white);
	display: flex;
	font-family: var(--font-title-condensed);
	font-size: 1.5rem;
	letter-spacing: 0.046875rem;
	line-height: 1.41;
	overflow: hidden;
	padding-top: var(--space-small);
	padding-bottom: var(--space-small);
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	min-height: 50vw;
}

@media (--mq-from-small) {
	.banner {
		min-height: 200px;
	}
}

@media (--mq-from-wide) {
	.banner {
		font-size: 4rem;
		min-height: 364px;
	}
}

.banner::before {
	background-color: var(--color-green);
	content: "";
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
	z-index: 2;
}

@supports (mix-blend-mode: screen) {
	.banner::before {
		mix-blend-mode: screen;
	}
}

.banner__link:focus .banner::before,
.banner__link:hover .banner::before {
	opacity: 0.5;
}

@supports (mix-blend-mode: screen) {
	.banner__link:focus .banner::before,
	.banner__link:hover .banner::before {
		opacity: 1;
	}
}

.banner__link:focus .banner {
	outline: 0;
}

.sg-kitchensink__fallback-font .banner,
html:not(.wf-active) .banner {
	font-family: var(--font-title-condensed-fallback);
	font-weight: var(--font-weight-bold);
	letter-spacing: -0.5px;
	line-height: 1.41;
}

@media (--mq-from-small) {
	.sg-kitchensink__fallback-font .banner,
	html:not(.wf-active) .banner {
		letter-spacing: -0.2px;
	}
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font .banner,
	html:not(.wf-active) .banner {
		letter-spacing: -2px;
	}
}

.banner__link {
	display: block;
	text-decoration: none;
	width: 100%;
}

.banner__background {
	padding-top: 0 !important;
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.banner__background__asset {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.banner__background__asset {
		object-fit: cover;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		height: 100%;
	}
}

.banner__link:focus .banner__background__asset,
.banner__link:hover .banner__background__asset {
	filter: grayscale(1);
}

.banner__content {
	box-sizing: border-box;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	position: relative;
	width: 100%;
	z-index: 3;
}
