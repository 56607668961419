.video-embed-player {
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.video-embed-player__iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
