.table {
	margin-top: 0;
	margin-bottom: 0;
	position: relative;
	width: 100%;
}

.table__row {
	border-bottom: 2px solid var(--color-black);
	box-sizing: border-box;
	display: flex;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	width: 100%;
}

@media (min-width: 1280px) {
	.table__row {
		padding-right: 2.5rem;
	}
}

@media (--mq-from-wide) {
	.table__row {
		padding-right: 5rem;
	}
}

.table__title {
	flex-basis: calc(35% - 1.25rem);
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 1.25rem;
}

@media (--mq-from-small) {
	.table__title {
		flex-basis: calc(35% - 1.5625rem);
		margin-right: 1.5625rem;
	}
}

.table__description {
	flex-basis: 65%;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: 0;
}
