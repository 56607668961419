.columns {
	position: relative;
}

@media (min-width: 768px) {
	.columns {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	}
}

.columns .columns__column {
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
}

@media (min-width: 768px) {
	.columns .columns__column {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (max-width: 767px) {
	.columns .columns__column:not(:last-child):not(only-child) {
		margin-bottom: 1.25rem;
	}
}

@media (min-width: 768px) {
	.columns.columns--size-2 .columns__column {
		flex-basis: calc(50% - 0.5rem);
		width: calc(50% - 0.5rem);
		max-width: calc(50% - 0.5rem);
	}
}

@media (min-width: 1380px) {
	.columns.columns--size-2 .columns__column {
		flex-basis: calc(50% - 0.625rem);
		width: calc(50% - 0.625rem);
		max-width: calc(50% - 0.625rem);
	}
}

@media (min-width: 768px) {
	.columns.columns--size-3 .columns__column {
		flex-basis: calc(33.3333% - 0.625rem);
		width: calc(33.3333% - 0.625rem);
		max-width: calc(33.3333% - 0.625rem);
	}
}

@media (min-width: 1380px) {
	.columns.columns--size-3 .columns__column {
		flex-basis: calc(33.3333% - 0.8125rem);
		width: calc(33.3333% - 0.8125rem);
		max-width: calc(33.3333% - 0.8125rem);
	}
}
