.theme-green {
	background-color: var(--color-green);
}

.theme-black {
	background-color: var(--color-black);
}

.theme-white {
	background-color: var(--color-white);
}

.theme-black {
	color: var(--color-white);
}

.theme-green,
.theme-white {
	color: var(--color-black);
}

.theme-text-color-green {
	color: var(--color-green);
}

.theme-text-color-black {
	color: var(--color-black);
}

.theme-text-color-white {
	color: var(--color-white);
}
