.legal-navigation {
	list-style: none;
	margin-top: 0;
	margin-bottom: calc(var(--space-xsmall) * -1);
	padding-left: 0;
}

@media (--mq-up-to-small) {
	.legal-navigation {
		display: block;
		text-align: center;
		width: 100%;
	}
}

.legal-navigation__item {
	display: inline-block;
	margin-bottom: var(--space-xsmall);
}

.legal-navigation__item + .legal-navigation__item {
	margin-left: var(--space-xsmall);
}

.legal-navigation__link {
	color: var(--color-green);
	text-decoration: underline;
	transition: color 96ms linear;
}

.legal-navigation__link:focus {
	outline: 0;
}

.legal-navigation__link:hover,
.legal-navigation__link:focus {
	color: var(--color-black);
	text-decoration: none;
}
