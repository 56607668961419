/* 🚨 Marquee animation is set with inline CSS at marquee-css.twig */

.marquee {
	overflow: hidden;
	position: relative;
	width: 100%;
}

.marquee::after,
.marquee::before {
	content: "";
	position: absolute;
	top: 0;
	width: 43px;
	height: 100%;
	z-index: 3;
}

@media (--mq-from-small) {
	.marquee::after,
	.marquee::before {
		width: 70px;
	}
}

.marquee::after {
	background-image: linear-gradient(to left, var(--color-white), var(--color-transparent-white-0));
	right: 0;
}

.marquee::before {
	background-image: linear-gradient(to right, var(--color-white), var(--color-transparent-white-0));
	left: 0;
}

/* This marquee has a black background, so it needs black gradients */

.faq-list__title .marquee::after {
	background-image: linear-gradient(to left, var(--color-black), var(--color-transparent-black-0));
	right: 0;
}

.faq-list__title .marquee::before {
	background-image: linear-gradient(to right, var(--color-black), var(--color-transparent-black-0));
	left: 0;
}

.wf-inactive .marquee.marquee--news {
	display: none !important;
}

.marquee.marquee--news .marquee__item {
	display: inline-block;
	overflow: hidden;
}

html.wf-inactive .marquee__news__falback {
	display: block;
}

html:not(.wf-inactive) .marquee__news__falback {
	display: none;
}

.marquee__rail {
	position: relative;
	white-space: nowrap;
	z-index: 1;
}

/* Don't animate for users that prefer reduced motion */
@media screen and (prefers-reduced-motion: reduce) {
	.marquee__rail {
		animation: none !important;
	}
}
