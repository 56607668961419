.navigation-overview {
	background-color: var(--color-green);
	box-sizing: border-box;
	color: var(--color-black);
	display: block;
	padding-top: var(--space-xsmall);
	padding-bottom: var(--space-xsmall);
}

@media (--mq-from-wide) {
	.navigation-overview {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

.navigation-overview__wrapper {
	display: grid;
	gap: var(--space-xsmall);
	grid-template-columns: 1fr;
}

@media (--mq-from-xsmall) {
	.navigation-overview__wrapper {
		gap: var(--space-xsmall);
		grid-template-columns: 1fr 1fr;
	}
}

@media (--mq-from-small) {
	.navigation-overview__wrapper {
		gap: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.navigation-overview__wrapper {
		gap: var(--space-medium);
		grid-template-columns: 2fr 3fr 4fr 50%;
	}
}

@media (--mq-from-large) {
	.navigation-overview__wrapper {
		gap: var(--space-large);
	}
}

@media (--mq-from-medium) {
	.navigation-overview__column.navigation-overview__column--meta {
		justify-self: end;
		max-width: 400px;
	}
}

.navigation-overview__logo {
	color: currentColor;
	text-decoration: none;
}

.navigation-overview__logo .icon-logo {
	width: 102px;
	height: 22px;
}

@media (--mq-from-small) {
	.navigation-overview__logo .icon-logo {
		width: 93px;
		height: 20px;
	}
}

.navigation-overview__contact a:not([class]) {
	color: currentColor;
	text-decoration: underline;
	transition: color 96ms linear;
}

.navigation-overview__contact a:focus:not([class]) {
	outline: 0;
}

.navigation-overview__contact a:focus:not([class]),
.navigation-overview__contact a:hover:not([class]) {
	color: var(--color-white);
	text-decoration: none;
}

.navigation-overview__partners {
	font-size: 0;
}

.navigation-overview__partners__item {
	display: inline-block;
	margin-right: var(--space-xsmall);
	margin-bottom: var(--space-xsmall);
	vertical-align: middle;
}

.navigation-overview__partners__item:focus,
.navigation-overview__partners__item:hover {
	filter: invert(100%);
}
