.wrapper {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	max-width: 1280px;
}

@media (max-width: 1320px) {
	.wrapper {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (--mq-up-to-small) {
	.wrapper--full-width-on-mobile {
		padding-right: 0;
		padding-left: 0;
	}
}
