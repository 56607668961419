/* Full-width container with vertical spacing and horizontal padding */

.row {
	margin-top: 1.5625rem;
	margin-bottom: 1.5625rem;
	overflow: hidden;
	width: 100%;
}

@media (--mq-from-medium) {
	.row {
		border-width: 2px;
	}
}

@media (--mq-from-medium) {
	.row {
		margin-top: 1.875rem;
		margin-bottom: 1.875rem;
	}
}

@media (--mq-from-wide) {
	.row {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

@media (--mq-from-large) {
	.row {
		margin-top: 3.75rem;
		margin-bottom: 3.75rem;
	}
}

.l-site-container__content .row:last-child {
	margin-bottom: 0;
}

.l-site-container__footer .row:first-child {
	margin-top: 0;
}
