/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.button {
	background-color: transparent;
	border: 2px solid currentColor;
	border-radius: 1.875rem;
	color: currentColor;
	display: inline-block;
	font-family: var(--font-title-condensed);
	font-size: 0.75rem;
	letter-spacing: 0.0625rem;
	line-height: 1.2;
	padding: 0.375rem 0.9375rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 96ms linear;
}

@media (--mq-from-medium) {
	.button {
		font-size: 0.9375rem;
		padding: 0.5625rem 1.25rem;
	}
}

@media (--mq-from-wide) {
	.button {
		font-size: 1.125rem;
		line-height: 1.25;
		padding: 0.75rem 1.5625rem;
	}
}

.button:disabled,
.button[disabled] {
	color: rgb(177 177 177);
	cursor: default;
}

.button:disabled:hover,
.button[disabled]:hover {
	color: rgb(177 177 177);
	cursor: default;
}

.button:focus,
.button:hover {
	color: var(--color-green);
}

.button:focus {
	outline: 0;
}

.button:hover {
	cursor: pointer;
}

.text .button {
	margin-top: 1rem;
	margin-right: 0.5rem;
}

.button.button--small {
	padding: 0.375rem 0.5rem;
}

@media (--mq-from-medium) {
	.button.button--small {
		padding: 0.5625rem 0.625rem;
	}
}

@media (--mq-from-wide) {
	.button.button--small {
		font-size: 0.9375rem;
		line-height: 1.2;
		padding: 0.5625rem 0.625rem;
	}
}

@media (--mq-from-small) {
	.button.button--show-up-to-small {
		display: none;
	}
}

@media (--mq-up-to-small) {
	.button.button--show-from-small {
		display: none;
	}
}

.button[submitting] {
	animation: SUBMITTING 480ms linear infinite alternate;
}

@keyframes SUBMITTING {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.8;
	}
}
