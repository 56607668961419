.faq-tips {
	background-color: var(--color-black);
	box-sizing: border-box;
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 2.5rem 1.25rem 0 1.25rem;
	justify-content: space-between;
	align-items: center;
	text-align: center;
}

@media (--mq-from-small) {
	.faq-tips {
		padding-top: 1.875rem;
	}
}

@media (--mq-from-medium) {
	.faq-tips {
		padding-top: 3.25rem;
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

@media (min-width: 1280px) {
	.faq-tips {
		padding-top: 4.375rem;
		padding-right: 3.125rem;
		padding-left: 3.125rem;
	}
}

@media (min-width: 1320px) {
	.faq-tips {
		min-height: calc((1280px * 0.33) - 0.625rem);
	}
}

.faq-tips__body {
	margin-bottom: 2rem;
}

.faq-tips__body:last-child,
.faq-tips__body:only-child {
	margin-bottom: 0;
	padding-bottom: 2.5rem;
}

@media (--mq-from-small) {
	.faq-tips__body:last-child,
	.faq-tips__body:only-child {
		padding-bottom: 1.875rem;
	}
}

@media (min-width: 1280px) {
	.faq-tips__body:last-child,
	.faq-tips__body:only-child {
		padding-bottom: 4.375rem;
	}
}

.faq-tips__title {
	margin-bottom: 1.25rem;
}

@media (min-width: 1280px) {
	.faq-tips__title {
		margin-bottom: 1.6875rem;
	}
}

@media (--mq-from-medium) {
	.faq-tips__content {
		margin-right: auto;
		margin-left: auto;
		max-width: 310px;
	}
}

.faq-tips__team-member {
	margin-bottom: -40px;
}

/* 1/2 thumbnail height */
@media (min-width: 1280px) {
	.faq-tips__team-member {
		margin-bottom: -60px;
	}
}

.faq-tips__team-member__title {
	display: inline-block;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	margin-bottom: 1rem;
}

@media (min-width: 1280px) {
	.faq-tips__team-member__title {
		font-size: 1rem;
		margin-bottom: 1.25rem;
	}
}

.faq-tips__team-member__thumbnail {
	border-radius: 100%;
	display: block;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
	padding-top: 0 !important;
	width: 80px !important;
	height: 80px;
}

@media (min-width: 1280px) {
	.faq-tips__team-member__thumbnail {
		width: 120px !important;
		height: 120px;
	}
}
