/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.mosaic-media {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="1:1"] {
		padding-top: 100%;
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="1:2"] {
		padding-top: 200%;
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="2:1"] {
		padding-top: 50%;
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="2:3"] {
		padding-top: calc(100% * (3 / 2));
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="3:2"] {
		padding-top: calc(100% * (2 / 3));
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="3:4"] {
		padding-top: calc(100% * (4 / 3));
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="4:3"] {
		padding-top: calc(100% * (3 / 4));
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="16:9"] {
		padding-top: calc(100% * (9 / 16));
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="16:11"] {
		padding-top: calc(100% * (11 / 16));
	}
}

@media (min-width: 568px) {
	.mosaic-media[data-aspect-ratio="21:9"] {
		padding-top: calc(100% * (9 / 21));
	}
}

.mosaic-media__picture {
	display: block;
	width: 100%;
}

@media (min-width: 568px) {
	.mosaic-media__picture {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
}

.mosaic-media__picture__asset,
.mosaic-media__video {
	display: block;
	width: 100%;
	height: auto;
}

@media (min-width: 568px) {
	.mosaic-media__picture__asset,
	.mosaic-media__video {
		display: block;
		margin-top: 0;
		margin-bottom: 0;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		max-width: none;
		height: 100%;
		z-index: 0;
	}

	@supports (object-fit: contain) or (object-fit: cover) {
		.mosaic-media__picture__asset,
		.mosaic-media__video {
			left: 0;
			transform: none;
			width: 100%;
		}
	}
}

@media (min-width: 568px) {
	@supports (object-fit: contain) {
		.mosaic-media[fit="contain"] .mosaic-media__picture__asset,
		.mosaic-media[fit="contain"] .mosaic-media__video {
			object-fit: contain;
		}
	}
}

@media (min-width: 568px) {
	@supports (object-fit: cover) {
		.mosaic-media[fit="cover"] .mosaic-media__picture__asset,
		.mosaic-media[fit="cover"] .mosaic-media__video {
			object-fit: cover;
		}
	}
}

.mosaic-media__caption {
	box-sizing: border-box;
	color: var(--color-white);
	font-size: 0.75rem;
	font-weight: 500;
	padding: 1rem 1.25rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

@media (min-width: 768px) {
	.mosaic-media__caption {
		padding-top: 1.6875rem;
		padding-bottom: 1.6875rem;
	}
}

@media (min-width: 1380px) {
	.mosaic-media__caption {
		font-size: 0.8125rem;
		padding: 1.75rem 2.0625rem;
	}
}
