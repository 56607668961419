.navigation-list {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.navigation-list__item {
	margin-bottom: 0.625rem;
}

.navigation-list__link {
	color: currentColor;
	text-decoration: underline;
	transition: color 96ms linear;
}

.navigation-list__link:focus {
	outline: 0;
}

.navigation-list__link:focus,
.navigation-list__link:hover,
.current-menu-item .navigation-list__link {
	color: var(--color-white);
}

.current-menu-item .navigation-list__link {
	font-weight: var(--font-weight-bold);
}
