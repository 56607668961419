@media (--mq-from-small) {
	.l-faq {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.l-faq__list,
.l-faq__tips {
	overflow: hidden;
}

@media (--mq-up-to-small) {
	.l-faq__list:not(:last-child):not(:only-child),
	.l-faq__tips:not(:last-child):not(:only-child) {
		margin-bottom: var(--space-xsmall);
	}
}

@media (--mq-from-small) {
	.l-faq__list {
		flex-basis: calc(60% - 0.5625rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-medium) {
	.l-faq__list {
		flex-basis: calc(67% - 0.5625rem);
	}
}

@media (min-width: 1280px) {
	.l-faq__list {
		flex-basis: calc(67% - 0.625rem);
	}
}

@media (--mq-from-small) {
	.l-faq__tips {
		flex-basis: calc(40% - 0.5625rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-medium) {
	.l-faq__tips {
		flex-basis: calc(33% - 0.5625rem);
	}
}

@media (min-width: 1280px) {
	.l-faq__tips {
		flex-basis: calc(33% - 0.625rem);
	}
}
