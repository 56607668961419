body {
	background-color: var(--color-white);
	box-sizing: border-box;
	color: var(--color-black);
	overflow-x: hidden;
	min-height: 100vh;
}

textarea {
	resize: none;
}

hr {
	background-color: currentColor;
	border: none;
	height: 2px;
}

img,
video {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border: 2px solid currentColor;
	border-collapse: collapse;
	box-sizing: border-box;
	margin: 2rem 0;
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	padding: 1.25rem;
	text-align: left;
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	line-height: 1.86;
	list-style: none;
	margin: 0 0 2rem 0;
	padding-left: 0;
}

ol:not([class]) ol:not([class]),
ul:not([class]) ol:not([class]) {
	padding-left: 1.25rem;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ol:not([class]) {
	counter-reset: list;
}

li:not([class]) {
	margin: 0;
	padding: 0;
	position: relative;
}

ul:not([class]) > li:not([class]) {
	padding-left: 1.3125rem;
	position: relative;
}

ul:not([class]) > li:not([class])::before {
	content: url("../images/list-icon.svg");
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 0.6875rem;
	height: 0.6875rem;
}

ol:not([class]) > li:not([class])::before {
	content: counter(list) ". ";
	counter-increment: list;
}

ol:not([class]) ol:not([class]) > li:not([class])::before,
ul:not([class]) ol:not([class]) > li:not([class])::before {
	content: counter(list, lower-alpha) ") ";
	counter-increment: list;
}

a:not([class]) {
	color: var(--color-green);
	text-decoration: none;
	transition: color 96ms linear;
}

a:focus:not([class]) {
	outline: 0;
}

a:focus:not([class]),
a:hover:not([class]) {
	color: var(--color-green);
}
