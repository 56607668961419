/*
 * This layouts contains the (1) main content and the (2) site footer.
 * It make sure the footer always sticks to the bottom.
 */

.l-site-container {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-top: var(--site-header-height);
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 100vh;
}

@media (--mq-from-small) {
	.l-site-container {
		padding-top: var(--site-header-height-small);
	}
}

@media (--mq-from-wide) {
	.l-site-container {
		padding-top: var(--site-header-height-wide);
	}
}

/* (1) */
.l-site-container__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	padding-bottom: 1.5625rem;
	width: 100%;
}

@media (--mq-from-medium) {
	.l-site-container__content {
		padding-bottom: 1.875rem;
	}
}

@media (--mq-from-wide) {
	.l-site-container__content {
		padding-bottom: 2rem;
	}
}

@media (--mq-from-large) {
	.l-site-container__content {
		padding-bottom: 3.75rem;
	}
}

/* (2) */
.l-site-container__footer {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
}
