* {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: var(--font-weight-light);
	line-height: 1.57;
}

@media (--mq-from-wide) {
	body {
		font-size: 1rem;
		line-height: 1.75;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: var(--font-weight-light);
	margin-top: 0.875rem;
	margin-bottom: 0.875rem;
}

@media (--mq-from-small) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-wide) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

/*
 * The marquee component uses calculations based on this type style.
 * If you change it, go re-calc and update it at marquee.twig
 */
h1,
h2,
.type-a {
	font-family: var(--font-title-condensed);
	font-size: 2rem;
	line-height: 1.4;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	h1,
	h2,
	.type-a {
		font-size: 2.25rem;
	}
}

@media (--mq-from-medium) {
	h1,
	h2,
	.type-a {
		font-size: 3.25rem;
	}
}

@media (--mq-from-wide) {
	h1,
	h2,
	.type-a {
		font-size: 4rem;
	}
}

.sg-kitchensink__fallback-font h1,
html:not(.wf-active) h1,
.sg-kitchensink__fallback-font h2,
html:not(.wf-active) h2,
.sg-kitchensink__fallback-font .type-a,
html:not(.wf-active) .type-a {
	font-family: var(--font-title-condensed-fallback);
	font-weight: var(--font-weight-bold);
	letter-spacing: -0.5px;
}

@media (--mq-from-small) {
	.sg-kitchensink__fallback-font h1,
	html:not(.wf-active) h1,
	.sg-kitchensink__fallback-font h2,
	html:not(.wf-active) h2,
	.sg-kitchensink__fallback-font .type-a,
	html:not(.wf-active) .type-a {
		letter-spacing: -1.35px;
	}
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font h1,
	html:not(.wf-active) h1,
	.sg-kitchensink__fallback-font h2,
	html:not(.wf-active) h2,
	.sg-kitchensink__fallback-font .type-a,
	html:not(.wf-active) .type-a {
		letter-spacing: -2px;
	}
}

h3,
blockquote > p:first-child,
.type-b {
	font-family: var(--font-title);
	font-size: 1.125rem;
	line-height: 1.56;
}

@media (--mq-from-small) {
	h3,
	blockquote > p:first-child,
	.type-b {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

@media (--mq-from-wide) {
	h3,
	blockquote > p:first-child,
	.type-b {
		font-size: 1.5rem;
		line-height: 1.67;
	}
}

.sg-kitchensink__fallback-font h3,
html:not(.wf-active) h3,
.sg-kitchensink__fallback-font blockquote > p:first-child,
html:not(.wf-active) blockquote > p:first-child,
.sg-kitchensink__fallback-font .type-b,
html:not(.wf-active) .type-b {
	font-family: var(--font-title-fallback);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.95px;
}

@media (--mq-from-small) {
	.sg-kitchensink__fallback-font h3,
	html:not(.wf-active) h3,
	.sg-kitchensink__fallback-font blockquote > p:first-child,
	html:not(.wf-active) blockquote > p:first-child,
	.sg-kitchensink__fallback-font .type-b,
	html:not(.wf-active) .type-b {
		letter-spacing: 1.25px;
	}
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font h3,
	html:not(.wf-active) h3,
	.sg-kitchensink__fallback-font blockquote > p:first-child,
	html:not(.wf-active) blockquote > p:first-child,
	.sg-kitchensink__fallback-font .type-b,
	html:not(.wf-active) .type-b {
		letter-spacing: 1.7px;
	}
}

h4,
.type-c {
	font-family: var(--font-title-condensed);
	font-size: 1.25rem;
	line-height: 1.2;
	text-transform: uppercase;
}

@media (--mq-from-wide) {
	h4,
	.type-c {
		font-size: 1.625rem;
		line-height: 1.54;
	}
}

.sg-kitchensink__fallback-font h4,
html:not(.wf-active) h4,
.sg-kitchensink__fallback-font .type-c,
html:not(.wf-active) .type-c {
	font-family: var(--font-title-condensed-fallback);
	letter-spacing: -0.65px;
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font h4,
	html:not(.wf-active) h4,
	.sg-kitchensink__fallback-font .type-c,
	html:not(.wf-active) .type-c {
		letter-spacing: -0.8px;
	}
}

h5,
.type-d {
	font-family: var(--font-title);
	font-size: 1rem;
	line-height: 1.63;
}

@media (--mq-from-small) {
	h5,
	.type-d {
		font-size: 1.125rem;
		line-height: 1.56;
	}
}

@media (--mq-from-wide) {
	h5,
	.type-d {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

.sg-kitchensink__fallback-font h5,
html:not(.wf-active) h5,
.sg-kitchensink__fallback-font .type-d,
html:not(.wf-active) .type-d {
	font-family: var(--font-title-fallback);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.9px;
}

@media (--mq-from-small) {
	.sg-kitchensink__fallback-font h5,
	html:not(.wf-active) h5,
	.sg-kitchensink__fallback-font .type-d,
	html:not(.wf-active) .type-d {
		letter-spacing: 1.25px;
	}
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font h5,
	html:not(.wf-active) h5,
	.sg-kitchensink__fallback-font .type-d,
	html:not(.wf-active) .type-d {
		letter-spacing: 1.45px;
	}
}

h6,
.type-e {
	font-family: var(--font-title-condensed);
	font-size: 1rem;
	letter-spacing: 1px;
	line-height: 1.63;
	text-transform: uppercase;
}

@media (--mq-from-wide) {
	h6,
	.type-e {
		font-size: 1.125rem;
		line-height: 1.67;
	}
}

.sg-kitchensink__fallback-font h6,
html:not(.wf-active) h6,
.sg-kitchensink__fallback-font .type-e,
html:not(.wf-active) .type-e {
	font-family: var(--font-title-condensed-fallback);
	letter-spacing: 0.55px;
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font h6,
	html:not(.wf-active) h6,
	.sg-kitchensink__fallback-font .type-e,
	html:not(.wf-active) .type-e {
		letter-spacing: 0.5px;
	}
}

.type-f {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: var(--font-weight-light);
	letter-spacing: 0;
	line-height: 1.57;
	text-transform: none;
}

@media (--mq-from-wide) {
	.type-f {
		font-size: 1rem;
		line-height: 1.75;
	}
}

th {
	font-family: var(--font-title);
	font-weight: var(--font-weight-light);
}

.sg-kitchensink__fallback-font th,
html:not(.wf-active) th {
	font-family: var(--font-title-fallback);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.8px;
}

@media (--mq-from-wide) {
	.sg-kitchensink__fallback-font th,
	html:not(.wf-active) th {
		letter-spacing: 0.9px;
	}
}

blockquote {
	color: var(--color-green);
	margin-top: 1.5625rem;
	margin-bottom: 1.5625rem;
}

@media (--mq-from-wide) {
	blockquote {
		margin-top: 1.875rem;
		margin-bottom: 1.875rem;
	}
}

blockquote p:not(:last-child):not(:only-child) {
	margin-bottom: 0.5rem;
}

i,
em {
	font-style: italic;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

p {
	margin-top: 0;
	margin-bottom: 0.9375rem;
}

p:only-child,
p:last-child {
	margin-bottom: 0;
}

.sg-kitchensink__fallback-font > p,
.sg-kitchensink__fallback-font > table,
.sg-kitchensink__fallback-font > ul,
.sg-kitchensink__fallback-font > ol,
.sg-kitchensink__fallback-font > dl {
	font-family: var(--font-sans);
}

pre {
	font-family: monospace;
}

.sg-kitchensink__fallback-font > pre {
	font-family: monospace;
}
