.language-list {
	box-sizing: border-box;
	display: inline-block;
	font-size: 0;
	list-style: none;
	margin: 0;
	padding-left: 1.25rem;
}

@media (--mq-from-small) {
	.language-list {
		padding-left: 0.875rem;
	}
}

@media (--mq-from-wide) {
	.language-list {
		padding-left: 1.6875rem;
	}
}

.language-list__item {
	display: inline-block;
	line-height: 1;
}

.language-list__item + .language-list__item {
	margin-left: 0.5rem;
}

@media (--mq-from-small) {
	.language-list__item + .language-list__item {
		margin-left: 0.25rem;
	}
}

@media (--mq-from-wide) {
	.language-list__item + .language-list__item {
		margin-left: 0.375rem;
	}
}

.language-list__link {
	color: currentColor;
	font-family: var(--font-title);
	font-size: 1.25rem;
	line-height: 1.25;
	opacity: 0.35;
	text-decoration: none;
	transition:
		color 96ms linear,
		opacity 96ms linear;
}

@media (--mq-up-to-small) {
	.language-list__link {
		line-height: 3.125rem;
		height: 3.125rem;
	}
}

@media (--mq-from-small) {
	.language-list__link {
		font-size: 0.8125rem;
	}
}

@media (--mq-from-wide) {
	.language-list__link {
		font-size: 1rem;
	}
}

.language-list__link:hover,
.language-list__link:focus {
	color: var(--color-green);
	opacity: 0.7;
}

.language-list__link:focus {
	outline: 0;
}

.language-list__item--active .language-list__link {
	opacity: 1;
}
