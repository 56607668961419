.l-error {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 460px;
}

.l-error__bottom,
.l-error__middle,
.l-error__top {
	width: 100%;
}

.l-error__bottom,
.l-error__top {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
}

.l-error__middle {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
}

.l-error__top__row:nth-child(1n + 3) {
	overflow: hidden;
}

@media (max-height: 1200px) {
	.l-error__top__row:nth-child(1n + 3) {
		height: 0;
	}
}

@media (--mq-from-wide) {
	.l-error__top__row:nth-child(1n + 3) {
		height: 0 !important;
	}
}

@media (min-height: 641px) and (max-height: 800px) {
	.l-error__top__row:nth-child(-n + 4) {
		height: auto;
	}
}

@media (min-height: 801px) and (max-height: 1000px) {
	.l-error__top__row:nth-child(-n + 5) {
		height: auto;
	}
}

@media (min-height: 1001px) and (max-height: 1200px) {
	.l-error__top__row:nth-child(-n + 6) {
		height: auto;
	}
}

.l-error__content,
.l-error__middle__wrapper {
	height: 100%;
}

.l-error__content {
	box-sizing: border-box;
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
}

@media (--mq-up-to-small) {
	.l-error__content {
		flex-direction: column;
	}
}

@media (--mq-from-small) {
	.l-error__content {
		padding-top: 3.75rem;
		padding-bottom: 3.75rem;
		justify-content: space-between;
	}
}

@media (--mq-from-medium) {
	.l-error__content {
		border-width: 2px;
		padding-top: 4.375rem;
		padding-bottom: 4.375rem;
	}
}

@media (--mq-up-to-small) {
	.l-error__content__column {
		width: 100%;
	}
}

@media (--mq-from-small) {
	.l-error__content__column {
		flex-basis: calc(50% - 1.25rem);
		flex-grow: 1;
		flex-shrink: 0;
		margin-right: 0.625rem;
		margin-left: 0.625rem;
	}
}

@media (--mq-up-to-small) {
	.l-error__content__column:not(:last-child):not(:only-child) {
		margin-bottom: 1.25rem;
	}
}
