.u-text-align-center {
	text-align: center;
}

.u-text-align-right {
	text-align: right;
}

.u-hidden {
	display: none !important;
}

.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

/*
 * LAYOUT UTILITIES
 *
 * These are generated by the settings on columns/a component.
 *
 * e.g. <div class="text {{ generate_utility_classes(settings) }}">
 *
 */
.u-frame-all-around-borders {
	border: 2px solid var(--color-black);
	box-sizing: border-box;
	padding: 1.25rem;
}

@media (--mq-from-medium) {
	.u-frame-all-around-borders {
		padding: 1.875rem;
	}
}

@media (--mq-from-wide) {
	.u-frame-all-around-borders {
		padding: 4.25rem 6.875rem;
	}
}

.columns .u-frame-all-around-borders {
	padding: 1.25rem;
}

@media (--mq-from-wide) {
	.columns .u-frame-all-around-borders {
		padding: 2.375rem 1.875rem;
	}
}

.u-frame-all-around-borders .u-frame-all-around-borders,
.u-frame-top-bottom-borders .u-frame-all-around-borders {
	border: 0;
	padding: 0;
}

.u-frame-top-bottom-borders:not(.u-frame-all-around-borders) {
	border-top: 2px solid var(--color-black);
	border-bottom: 2px solid var(--color-black);
	padding-top: 1.5625rem;
	padding-bottom: 1.5625rem;
}

@media (--mq-from-medium) {
	.u-frame-top-bottom-borders:not(.u-frame-all-around-borders) {
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;
	}
}

@media (--mq-from-wide) {
	.u-frame-top-bottom-borders:not(.u-frame-all-around-borders) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

.u-frame-all-around-borders .u-frame-top-bottom-borders:not(.u-frame-all-around-borders),
.u-frame-top-bottom-borders .u-frame-top-bottom-borders:not(.u-frame-all-around-borders) {
	border-top: 0;
	border-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}
